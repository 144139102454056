import React from "react";
import "../../../assets/css/global.css";
import styles from "./styles.module.css";
import Button from "../components/Button";

export default function VotreEntreprise() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Votre entreprise</h1>
        <p>
          Chaque jour, vous pensez à la possibilité... La possibilité de faire
          croître votre entreprise jusqu'à un niveau où les gens vous percevront
          non seulement comme le meilleur dans ce que vous faites, mais le seul
          qui remplisse vraiment la commande. Nul besoin d'être une grosse
          société pour faire une forte impression. NEBS vous offre une large
          gamme de produits novateurs pour vous aider à promouvoir votre
          entreprise.
        </p>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2 style={{ color: "unset", fontSize: "1.5em" }}>Automobile</h2>
            <p>
              Deluxe offre une gamme étendue de produits conçus pour les
              artisans du secteur de l’automobile.
            </p>
            <div className={styles.button_wrapper}>
              <Button
                text={"Voir les produits Automobile"}
                url="/votre-entreprise/automobile/"
              />
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2 style={{ color: "unset", fontSize: "1.5em" }}>Entrepreneurs</h2>
            <p>
              Deluxe offre une vaste gamme de produits conçus pour les
              entrepreneurs.
            </p>
            <div className={styles.button_wrapper}>
              <Button
                text={"Voir les produits Entrepreneurs"}
                url="/votre-entreprise/entrepreneurs/"
              />
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2 style={{ color: "unset", fontSize: "1.5em" }}>Hospitalité</h2>
            <p>
              Deluxe offre une vaste gamme de produits conçus pour les personnes
              oeuvrant dans le secteur de l’hébergement
            </p>
            <div className={styles.button_wrapper}>
              <Button
                text={"Voir les produits Hospitalité"}
                url="/votre-entreprise/hospitalit/"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2 style={{ color: "unset", fontSize: "1.5em" }}>
              Fabricants et grossistes
            </h2>
            <p>
              Deluxe offre une vaste gamme de produits conçus pour les personnes
              oeuvrant dans les secteurs manufacturier et de la vente en gros
            </p>
            <div className={styles.button_wrapper}>
              <Button
                text={"Voir les produits Fabricants et grossistes"}
                url="/votre-entreprise/fabricants-et-grossistes/"
              />
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2 style={{ color: "unset", fontSize: "1.5em" }}>
              Professionnels
            </h2>
            <p>
              Deluxe offre une vaste gamme de produits conçus pour les personnes
              oeuvrant dans les secteurs des services professionnels
            </p>
            <div className={styles.button_wrapper}>
              <Button
                text={"Voir les produits Professionnels"}
                url="/votre-entreprise/professionnels/"
              />
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2 style={{ color: "unset", fontSize: "1.5em" }}>Détaillants</h2>
            <p>
              Deluxe offre une vaste gamme de produits conçus pour les personnes
              oeuvrant dans le secteur de la vente au détail
            </p>
            <div className={styles.button_wrapper}>
              <Button
                text={"Voir les produits Détaillants"}
                url="/votre-entreprise/dtaillants/"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
