import React from "react";
import Layout from "../../other/layout";
import VotreEntreprise from "../../components/AC-StaticPages/VotreEntreprise/VotreEntreprise";
import SEO from "../../other/seo";

export default function votreEntreprise() {
  return (
    <Layout>
      <SEO title="Votre entreprise" />

      <VotreEntreprise />
    </Layout>
  );
}
